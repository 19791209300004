import React, { Component } from "react";
import Main from "./components/Main";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Posts from "./components/Posts";
import Footer from "./components/Footer";
import Discography from "./components/Discography";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import SinglePost from "./components/SinglePost";
import Calendar from "./components/calendar/Calendar";
import Education from "./components/education/Education";
import Collaborations from "./components/collaborations/Collaborations";
import Videos from "./components/videos/Videos";
import Press from "./components/press/Press";
import NewsletterButton from "./components/newsletterButton";
import Biog from "./components/Biog";
import EducationProjectFull from "./components/education/EducationProjectFull";
import CollaborationSingle from "./components/collaborations/CollaborationSingle";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {
    var switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) window.localStorage.setItem("lang", "en-US");
  }

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <Router>
        <div style={{ height: "100%" }}>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer
            show={this.state.sideDrawerOpen}
            click={this.drawerToggleClickHandler}
          />
          {backdrop}
          <main style={{ marginTop: "56px" }}></main>{" "}
          <Switch>
            <Route exact path='/' component={Main} />
            <Route path='/gallery' component={Gallery} />
            <Route path='/calendar' component={Calendar} />
            <Route path='/education/:id' component={EducationProjectFull} />
            <Route path='/education' component={Education} />
            <Route path='/collaborations/:id' component={CollaborationSingle} />
            <Route path='/collaborations' component={Collaborations} />
            <Route path='/contact' component={Contact} />
            <Route path='/posts/:id' component={SinglePost} />
            <Route path='/posts' component={Posts} />
            <Route path='/recordings' component={Discography} />
            <Route path='/video' component={Videos} />
            <Route path='/press' component={Press} />
            <Route path='/biography' component={Biog} />
          </Switch>
          <NewsletterButton />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
