export default {
    translations: {
        
            Tagline: "Recorder",
            PressQuote: "A charismatic virtuoso",
            Source: "The Times",
            Home:"Home",
            Contact:"Contact",
            ContactText: 'Get in touch with me via the form below, or the social media links - I’d love to hear from you!',
            ContactFormName: 'Your name:',
            ContactFormEmail: 'Your email:',
            ContactFormMessage: 'Your message',
            ContactFormButton: 'send',
            Biography: "Biography",
            Calendar: "Calendar",
            Archive: "Archive",
            UpComing: "Up & Coming Events:",
            Projects: "Projects",
            Education: "Education",
            Collaborations: "Collaborations",
            Media: "Media",
            Recordings: "Recordings",
            Gallery: "Gallery",
            Video: "Videos",
            News:"News",
            Press: "Press",
            NewsletterText: 'And don\'t forget to sign up to my newsletter to stay informed about my concerts and other activities!',
            NewsletterSignUp: "sign up to my newsletter",
            ViewConcert: 'view concert',
            ViewArchive: 'view complete archive',
            ReadMore: 'read more',
            ViewVideo: 'video ansehen',
            ShareThisPage: 'Share this page on social media',
            BackToAllProjects: 'back to all projects',
            PhotoDownload: 'Downloads',
            DownloadFile: 'download biography',
            
            
            
            
            Management1: 'For general management and bookings please contact ',
            Management1a: '(Senior Artist Manager at YCAT).',
            Management2: 'For North American management and bookings please contact ',
            Management2a: '(Senior VP, Artist Management at CAG).',

            CollaborationIntro: "My interest in contemporary music for recorder in particular has led to numerous collaborations with composers, which I have found to be a sure-fire way of discovering exciting possibilities of communication and experimentation – often hitherto unknown to me and the composer(s). Here are a few examples I’d like to share… ",
            EducationIntro: 'My passion for outreach work has enabled me to work with students of all levels of experience. Be it individual lessons and masterclasses or primary school music-making workshops I strive to make the music I enjoy performing accessible to people from all backgrounds. ',

            TheNightingale: "The Nightingale",
            TheNightingaleTag: "Uģis Prauliņš’s “The Nightingale” for recorder and choir is a colourful and joyous musical narration of the famous fairy-tale by Hans Christian Andersen.",
            TheNightingaleDetails: "In China, you know, the emperor is a Chinese, and all those about him are Chinamen also. The story I am going to tell you happened a great many years ago. The emperor’s palace was the most beautiful in the world. In the garden could be seen the most singular flowers – and beyond its limits there was a noble forest with lofty trees. In one of those trees lived a nightingale, who sang so beautifully that everyone would stop and listen… ",
            ConcertLink:"To listen to and watch the full WDR family concert go to:",
            WDR:  "WDR Rundfunkchor",
            NicolasFink: "Nicolas Fink - Conductor",
            Tabea: "Tabea Debus - Recorder",
            SeptemberOctober:"September - October 2017",
            LanguageOfBirds: "The Language of Birds by Philip Cashian",
            LanguageTag: "Commissioned by St John's Smith Square for Tabea Debus, 2016",
            Language1: "The recorder claims several roles in an operatic context. One of its main allegories is the imitation of birdsong, famously depicted by George Frideric Handel, Henry Purcell and many more.  Whether this implies that the recorder is capable of speaking the birds’ language or that it is merely playing the composer’s own version of this secret language is a different matter. The fascination with this phenomenon is a prevailing feature in music, as the newly composed recorder solo work “The Language of Birds” by Philip Cashian shows.",
            Language2:"“In mythology and folk tales from around the world the language of birds is often considered to be a magical language and the ability to understand it a sign of great wisdom. Whilst writing this short, virtuosic piece I began to realise that playing the recorder is extremely close to talking or singing and the instrument is a natural extension and amplification of the players body. This was very much in my mind whilst composing.” - Philip Cashian",
            Fantasie:"Throughout the past years it has become more and more each recorder player initiative to widen the “repertoire horizon” of this versatile instrument, not only through (re-) discovering already existing original music for the recorder but also by arranging other music – and commissioning new contemporary pieces. The latter is an especially intriguing process as the collaboration with a living composer opens up many exciting possibilities of communication and experimentation for both player and composer. Inspired by the 250th anniversary of Georg Philipp Telemann in 2017 the City Music Foundation and I have therefore taken the opportunity to commission new works and creative perspectives based on baroque music and to strengthen the musical connection between the 18th and 21st centuries. These twelve new works are based on Telemann’s XII Fantasie per il Flauto senza Basso, with an additional piece reflecting on the whole of Telemann’s twelve solo Fantasias. Each of these freshly-penned pieces is a reflection on thematic, harmonic or rhythmic material taken from the Fantasias, a reply to Telemann’s musical thoughts. ",
            Fantasie2:"The CD XXIV Fantasie per il Flauto was released by TYXart-the musicART label in April 2018.",
            Fantasie3:"\"I was very excited to get involved in this project with Tabea Debus and the City Music Foundation - I had not previously written for recorder, and therefore this has been a fantastic journey exploring the instrument and its capabilities. Solo works are always a challenge for a composer as it truly exposes both us and the performer, though working with Tabea has been an absolute pleasure and I am confident that she will perform the work exceptionally.” - Dani Howard",
            Fantasie4:"“It has been a very exciting project to be a part of and I am loving getting to know a whole family of instruments that is completely new to me. I have been studying my assigned Fantasia and have created my own ‘response’ to it by writing two additional movements in my own style to complement the existing ones.” - Misha Mullov-Abbado ",
            Fantasie5:"“It is always a pleasure to compose for a world-class performer, and even more so for such an exciting and innovative project. The project has given the chance to really get inside the Telemann Fantasias to understand what gives them their character, before creating a new piece reflecting on this, and taking into consideration the musical and instrumental developments that have occurred in the nearly 300 years since the Fantasias were composed. In composing for this project it has been a great pleasure to be able to learn more about the recorder and to be able to incorporate extended techniques that are specific to the instrument.” - Alastair Penman ",
            Fantasie6:"Dani Howard: “Two and a Half Minutes to Midnight” (No. 1)    ",
            Fantasie7:"Alastair Penman: “Mirrored Lines” (No. 2)    ",
            Fantasie8:"Oliver Leith “Bendy Broken Telemann” (No. 3)",
            Fantasie9:"Leo Chadburn: “Si la passion pour les plantes exotiques” (No. 4) ",
            Fantasie10:"Fumiko Miyachi: “Air” (No. 5)",
            Fantasie11:"Max de Wardener (No. 6)",
            Fantasie12:"Arne Gieshoff: “Entr’acte” (No. 7)",
            Fantasie13:"Ronald Corp: “Meditation and Gigue” (No. 8)",
            Fantasie14:"Moritz Eggert: “Fantasia in Stereo” (No. 9)",
            Fantasie15:"Misha Mullov-Abbado “Fantasia 10.2.0” (No. 10)",
            Fantasie16:"Frank Zabel “…fizzling out…” (No. 11)",
            Fantasie17:"Colin Matthews “Meditation“ (No. 12)",
            Fantasie18:"Laura Bowler: “TV Man” (all Fantasias)"
        
        
        
        
        
        
    }
}