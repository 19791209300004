const client = require('contentful').createClient({
    space: '7xf9uwssy44u',
    accessToken: 'e6ozP4w4OYcAp4sBGrabWZtlJWfXFp-DtLFeva6dnhE'
  })
  var gt = new Date().toISOString();

  
  const getNews = (lang) => client.getEntries({content_type: 'news', locale: lang}).then(response => response.items)
  const getBiog = (lang) => client.getEntries({content_type: 'biography', locale: lang}).then(response => response.items)
  const getRecordings = (lang) => client.getEntries({content_type: 'recordings', order:'-fields.order', locale: lang}).then(response => response.items)
  const getCollaborations = (lang) => client.getEntries({content_type: 'collaboration', locale: lang,  order:'-fields.order'}).then(response => response.items)
  const getCollaborationProject = (slug, lang) => client.getEntries({'sys.id': slug, content_type: 'collaboration', locale: lang}).then(response => response.items)
  const getEducation = (lang) => client.getEntries({content_type: 'education', locale: lang, order:'-fields.order'}).then(response => response.items)
  const getEducationProject = (slug, lang) => client.getEntries({'sys.id': slug, content_type: 'education', locale: lang}).then(response => response.items)
  const getVideos = () => client.getEntries({content_type: 'video', order:'-fields.order'}).then(response => response.items)
  const getFeaturedVideo = () => client.getEntries({content_type: 'featuredVideo'}).then(response => response.items)
  const getReviews = () => client.getEntries({content_type: 'press', order: '-fields.order'}).then(response => response.items)
  const getGallery = () => client.getEntries({content_type: 'gallery', order:'-fields.order'}).then(response => response.items)
  const getCalendar = () => client.getEntries({content_type: 'calendar',order: 'fields.date' ,'fields.date[gte]': gt
}).then(response => response.items)
  const getCalendarArchive = () => client.getEntries({content_type: 'calendar', order:'-fields.date', 'fields.date[lt]': gt
}).then(response => response.items)




  const getSinglePost = (slug, lang) => client.getEntries({'sys.id': slug, content_type: 'news', locale: lang}).then(response => response.items)
  




  export { getFeaturedVideo, getCollaborationProject, getEducationProject, getEducation, getCollaborations, getNews, getSinglePost, getBiog, getRecordings, getVideos, getReviews, getGallery, getCalendar, getCalendarArchive }