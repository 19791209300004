import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getBiog } from "../contentful";
import marked from "marked";
import TitleBar from "./TitleBar";

const Biog = () => {
  const [biog, setBiog] = useState([]);

  useEffect(() => {
    let switchedLang = window.localStorage.getItem("lang");
    if(!switchedLang){switchedLang = 'en-US'}

    getBiog(switchedLang).then((data) => setBiog(data));
  }, []);

  return (
    <div className="content biog-page">
      <TitleBar title={<Translator path="Biography" />} />

      {biog.map((biog) => {
        const block1 = marked(biog.fields.block1);
        const block2 = marked(biog.fields.block2);

        return (
          <div className=" text-box" >
            <Row>
              <Col>
                <div
                  dangerouslySetInnerHTML={{ __html: block1 }}
                />
              </Col>
              <Col>
                <img
                  style={{ width: "100%", marginBottom: "15px" }}
                  src={biog.fields.image.fields.file.url}
                  alt=""
                />
              </Col>
            </Row>
            <div  dangerouslySetInnerHTML={{ __html: block2 }} />
            <hr/>
            <div>
              <div style={{marginBottom:'15px'}} >
              {biog.fields.downloadImages && <h5><Translator path="PhotoDownload" /></h5> }
            {biog.fields.downloadImages &&
              biog.fields.downloadImages.map((photo) => (
                <a href={photo.fields.file.url} download target="_blank">
                  <img
                    style={{ height: "150px", margin:'3px' }}
                    src={photo.fields.file.url}
                  />
                </a>
              ))}
              </div>
            {biog.fields.downloadFile && (
              <a 
                className="xsmall-button"
                href={biog.fields.downloadFile.fields.file.url}
                download
                target="_blank"
              >
                <Translator path="DownloadFile" />
              </a>
            )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Biog;
