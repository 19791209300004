import React, { Component } from "react";
import { getSinglePost } from "../contentful";
import SingleNewsDisplay from './SingleNewsDisplay'

class SinglePost extends Component {
  state = {
  post: []
  };



  componentDidMount() {
    var switchedLang = window.localStorage.getItem('lang')

    getSinglePost(this.props.match.params.id, switchedLang).then((data) => 
      this.setState({
       post: data
      })
    );

  }

  render() {
    return (
      <div>
     {this.state.post.map(data => <SingleNewsDisplay news={data} />  )}
     </div>
    );
  }
}

export default SinglePost;
