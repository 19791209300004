import React, { Component } from 'react'
import Translator from '../i18n/Translator'
import { getCollaborations } from '../../contentful'
import  Collaboration  from './Collaboration'
import TitleBar from '../TitleBar'

class Collaborations extends Component {
    state = {
        collaborations: []
    }

    componentDidMount(){
        var lang = window.localStorage.getItem('lang')
        if(!lang){lang = 'en-US'}

        getCollaborations(lang).then(data => this.setState({collaborations: data}))
    }
    render(){
        return( 
        <div className="content">
        <TitleBar title={<Translator path="Collaborations"/>} />
        <p style={{ textAlign:'center',  color: 'rgb(235, 210, 192'}} ><Translator path="CollaborationIntro"/></p>
        {this.state.collaborations.map(project => <Collaboration project={project} />)}


        </div>
        
        )

    }
}

export default Collaborations
