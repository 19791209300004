import React, { useState, useEffect } from "react";
import "../styles/stylesheet.css";
import Hero from "./hero/Hero";
import { Row, Col } from "react-bootstrap";
import { getCalendar, getNews, getFeaturedVideo } from "../contentful";
import Event from "./Event";
import { Link } from "react-router-dom";
import marked from "marked";
import Translator from "./i18n/Translator";

const Main = () => {
  const [news, setNews] = useState([]);
  const [event, setEvent] = useState([]);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    var switchedLang = window.localStorage.getItem("lang");
    getCalendar().then((data) => setEvent(data));
    getNews(switchedLang).then((data) => setNews(data));
    getFeaturedVideo().then((data) => setVideo(data));
  }, []);

  return (
    <div>
      <Hero />
      <div className='contents home-middle'>
        <Row>
          <Col md={6}>
            <Link to='/posts'>
              <h3 style={{ textAlign: "center", color: "rgb(235, 210, 192" }}>
                {" "}
                <Translator path='News' />
              </h3>
            </Link>
            {news.slice(0, 1).map((news) => {
              // const content = marked(news.fields.content)
              // const excerpt = content.split(' ').slice(0, 20).join(' ') + '...'
              return (
                <div className='home-news'>
                  <div>
                    {news.fields.image && (
                      <img src={news.fields.image.fields.file.url} />
                    )}
                  </div>
                  <h5>{news.fields.title}</h5>
                  <Link className='xsmall-button' to='/posts'>
                    <Translator path='ReadMore' />
                  </Link>
                </div>
              );
            })}
          </Col>
          <Col md={6}>
            <Link to='/calendar'>
              <h3 style={{ textAlign: "center", color: "rgb(235, 210, 192" }}>
                {" "}
                <Translator path='Calendar' />
              </h3>
            </Link>
            {event.slice(0, 1).map((event) => (
              <Event class='home-news' date={event} />
            ))}
          </Col>
        </Row>
      </div>
      <div className='between1'></div>
      <div>
        {video.slice(0, 1).map((video) => {
          // const content = marked(video.fields.youTubeCode)

          return (
            <div className='home-video'>
              <iframe
                width='660'
                height='415'
                src={`https://www.youtube.com/embed/${video.fields.youTubeCode}`}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      </div>
      <div className='between2'></div>
    </div>
  );
};

export default Main;
