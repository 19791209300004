import React from 'react';
import Translator from './i18n/Translator'
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookIcon,
    FacebookShareButton,
  } from "react-share";


const NewsletterButton = () => {
    return (
        <div className="buttons-wrapper">
             <div className="sharing-buttons">
        <small><Translator path="ShareThisPage" /></small>
        <br></br>
        <TwitterShareButton
          url={window.location}
          children={<TwitterIcon size={24} />}
        />
        <LinkedinShareButton
          url={window.location}
          children={<LinkedinIcon size={24} />}
        />
        <FacebookShareButton
          url={window.location}
          children={<FacebookIcon size={24} />}
        />
      </div>{" "}
        <div className="button-wrapper">
            
            <a className="big-button" 
            href="https://tabeadebus.us12.list-manage.com/subscribe?u=03b696b9b9da94422bbf540bc&id=ac0d97450a" 
            target="_blank" >
                <Translator  path="NewsletterSignUp"/></a>

        
       
        </div>
   
        </div>
    )
}

export default NewsletterButton