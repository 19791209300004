import React, { useState, useEffect } from "react";
import Translator from "../i18n/Translator";
import TitleBar from "../TitleBar";
import marked from "marked";
import { getReviews } from "../../contentful";
import { Row, Col } from "react-bootstrap";
import image from '../../assets/imgs/recorders1.jpg'

const Press = () => {
  // const reviews = [
  //   {fields: {title: 'title', 
  //   quote: 'quote goes here', 
  //   link: 'www.adamwoolf.com',
  //   image: {
  //     fields:{
  //       file:{ url: image}
  //     }
  //   },
  //   recordingsPageLink: 'www.adamwoolf.com'
  //  },
  // }
  // ]
 
  const [reviews, setReviews] = useState([]);


  useEffect(() => {
    getReviews().then((data) => setReviews(data));
  }, []);

  return (
    <div className="content">
      <TitleBar title={<Translator path="Press" />} />
      {reviews.map((review) => {
        const full = marked(review.fields.quote);
        return (
          <div key={review.fields.title} className="recording">
            <Row>
              <Col md={8}>
                <h5>{review.fields.title}</h5>
                <section dangerouslySetInnerHTML={{ __html: full }} />
                {review.fields.link && (
                  <a 
                    target="_blank"
                    className="xsmall-button"
                    href={review.fields.link}
                  >
                    original
                  </a>
                )}
              </Col>
              <Col md={4}>
                 { review.fields.image && <img style={{width:'100%', marginBottom:'10px', marginTop:'10px'}} src={review.fields.image.fields.file.url} alt=""/>}
                {review.fields.recordingsPageLink && (
                  <a
                    target="_blank"
                    className="xsmall-button"
                    href="/recordings"
                  >
                    <Translator path="Recordings" />
                  </a>
                )}
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Press;
