import React from 'react'
import BlogItems from './BlogItems'
import { getNews } from '../contentful'
import TitleBar from './TitleBar'
import Translator from './i18n/Translator'

class Posts extends React.Component {
  state = {
    posts: []
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    var switchedLang = window.localStorage.getItem('lang')
    if(!switchedLang){switchedLang = 'en-US'}

    getNews(switchedLang).then(data => this.setState({posts: data}));
  }
  
   
 
  render() {
    return (
      
        <div className='content'>
        <TitleBar title={<Translator path="News" />} />
      <BlogItems posts={this.state.posts}/>
      <br/>
     
    </div>
    )
  }
}
export default Posts