import React from "react";
import marked from "marked";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import { FaTwitter, FaFacebook } from "react-icons/fa";

const SingleNewsDisplay = ({ news }) => {
  const content = marked(news.fields.content);

  return (
    <div className='news-item'>
      <h2 style={{ color: "rgb(119, 54, 4)" }}>{news.fields.title}</h2>
      {/* <p>by {news.fields.author.fields.name}</p> */}
      {news.fields.image ? (
        <img
          style={{ height: "250px", marginBottom: "25px" }}
          src={news.fields.image.fields.file.url}
        />
      ) : null}

      <div
        className='news-item--text'
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <hr></hr>
      <small>share this post on social media: </small>

      <div className='social'>
        <TwitterShareButton
          url={window.location}
          children={<TwitterIcon size={24} />}
        />
        <LinkedinShareButton
          url={window.location}
          children={<LinkedinIcon size={24} />}
        />
        <FacebookShareButton
          url={window.location}
          children={<FacebookIcon size={24} />}
        />
      </div>

      <div style={{ margin: 30 }}>
        <Link style={{ margin: "10px" }} className='xsmall-button' to='/posts/'>
          back to all articles
        </Link>
      </div>
    </div>
  );
};

export default SingleNewsDisplay;
