import React, { useEffect } from "react";
import {
  FaTwitter,
  FaFacebook,
  FaSpotify,
  FaYoutube,
  FaEnvelopeOpen,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import CAG from "../assets/CAGlogo.png";
import YCAT from "../assets/YCATlogo.jpg";
import { Row, Col } from "react-bootstrap";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='content'>
        <TitleBar title={<Translator path='Contact' />} />
        <div
          className='contact-middle'
          style={{ width: "80%", margin: "25px auto" }}
        >
          <Translator path='ContactText' />
          <div style={{ marginTop: "20px" }}></div>
        </div>

        <div className='contact-form'>
          <form
            className='form-group'
            action='https://formspree.io/f/mnqojggw'
            method='POST'
          >
            <label>
              {" "}
              <Translator path='ContactFormName' />
            </label>

            <input required className='form-control' type='text' name='name' />

            <label>
              {" "}
              <Translator path='ContactFormEmail' />
            </label>

            <input
              required
              className='form-control'
              type='email'
              name='_replyto'
            />
            <label>
              {" "}
              <Translator path='ContactFormMessage' />
            </label>

            <textarea
              required
              className='form-control'
              name='message'
            ></textarea>

            <button
              style={{ marginTop: "16px" }}
              className='xsmall-button'
              type='submit'
            >
              <Translator path='ContactFormButton' />
            </button>
          </form>
        </div>

        <div className='contact-social-icons'>
          <a
            href='https://www.facebook.com/TabeaDebusRecorder/'
            target='_blank'
          >
            <FaFacebook size={35} />
          </a>

          <a href='https://twitter.com/TabeaDebus' target='_blank'>
            <FaTwitter size={35} />
          </a>

          <a
            href='https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg'
            target='_blank'
          >
            <FaYoutube size={35} />
          </a>

          <a
            href='https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg'
            target='_blank'
          >
            <FaSpotify size={35} />
          </a>
        </div>
      </div>

      <div className='contact-image'></div>
      <div className='contact-newsletter-text'>
        <p>
          <Translator path='NewsletterText' />
        </p>
      </div>
    </>
  );
};

export default Contact;
