import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import Translator from '../i18n/Translator'

const videorow = {
  marginTop: "10px",
};
const CollaborationFull = ({ project }) => {
  let content;
  if(project.fields.content){ content = marked(project.fields.content);
  } else {
    content = null
  } 
  let video1;
  if (project.fields.youTube) {
    video1 = marked(project.fields.youTube);
  } else {
    video1 = null;
  }


  return (
    <div>

      
          <h3 style={{textAlign:'center'}}>{project.fields.title}</h3>
          <div className="text-center">
          {project.fields.mainImage ? (
            <img style={{width:'250px', marginBottom:'10px'}} src={project.fields.mainImage.fields.file.url} alt="" />
          ) : null}
          </div>
         <section dangerouslySetInnerHTML={{ __html: content }} />
        
       

  
       
       <section style={{width:'60%', margin:'auto'}} dangerouslySetInnerHTML={{ __html: video1 }} />

   
            {project.fields.gallery ? project.fields.gallery
        .map(image =>   <img  style={{ height:'200px', margin:'10px'}} src={image.fields.file.url}/>)
         : null } 
         
<div style={{marginTop: '20px'}}>
      <Link  to="/collaborations" className="xsmall-button">
<Translator path="BackToAllProjects" />      </Link>
      </div>
    </div>
  );
};

export default CollaborationFull;
