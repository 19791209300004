import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import marked from "marked";

const videorow = {
  marginTop: "10px",
};
const SingleProjectPage = ({ project }) => {
  let content;
  if(project.fields.content){ content = marked(project.fields.content);
  } else {
    content = null
  } 
  let video1;
  if (project.fields.videoLink) {
    video1 = marked(project.fields.videoLink);
  } else {
    video1 = null;
  }
  let video2;
  if (project.fields.videoLink2) {
    video2 = marked(project.fields.videoLink2);
  } else {
    video2 = null;
  }
  let video3;
  if (project.fields.videoLink3) {
    video3 = marked(project.fields.videoLink3);
  } else {
    video3 = null;
  }

  return (
    <div>
      <Row>
        <Col md={8}>
          <h3>{project.fields.title}</h3>
          <section dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
        <Col md={4}>
          {project.fields.mainImage ? (
            <img style={{width:'80%'}} src={project.fields.mainImage.fields.file.url} alt="" />
          ) : null}
        </Col>
      </Row>
      <div style={videorow}>
        <Row>
          <Col>
            <section dangerouslySetInnerHTML={{ __html: video1 }} />
          </Col>
          <Col>
            {" "}
            <section dangerouslySetInnerHTML={{ __html: video2 }} />
          </Col>
          <Col>
            {" "}
            <section dangerouslySetInnerHTML={{ __html: video3 }} />
          </Col>
        </Row>
      </div>
   
            {project.fields.gallery ? project.fields.gallery
        .map(image =>   <img  style={{ height:'200px', margin:'10px'}} src={image.fields.file.url}/>)
         : null } 
         

      <Link  to="/education" className="xsmall-button">
        back to all projects
      </Link>
    </div>
  );
};

export default SingleProjectPage;
