import React, { Component } from "react";
import { getCollaborationProject } from "../../contentful";
import CollaborationFull from "./CollaborationFull";

class CollaborationSingle extends Component {
  state = {
    project: [],
  };

  componentDidMount() {
    var switchedLang = window.localStorage.getItem("lang");

    getCollaborationProject(this.props.match.params.id, switchedLang).then((data) =>
      this.setState({ project: data })
    );
  }

  render() {
    return (
      <div className="recording">
        {this.state.project.map((item) => (
          <CollaborationFull project={item} />
        ))}
      </div>
    );
  }
}

export default CollaborationSingle;
