import React, { Component } from 'react'
import Translator from '../i18n/Translator'
import { getVideos } from '../../contentful'
import Video from './video'
import { Row, Col } from 'react-bootstrap'
import TitleBar from '../TitleBar'

class Videos extends Component {
    state = {
        videos: []
    }

componentDidMount(){
    getVideos().then(data => this.setState({videos: data}))
}

    render(){
        return (
        <div className="content">
        <TitleBar title={<Translator path="Video"/>} />

        <Row>
        {this.state.videos.map((video) =>  <Video video={video} />)}
        </Row>
        </div>
        )
    }
}

export default Videos
