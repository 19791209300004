export default {
    translations: {
        
            Tagline: "Blockflöte",
            PressQuote: "Eine charismatische Virtuosin",
            Source: "The Times",
            Home:"Home",
            Contact:"Kontakt",
            ContactText: 'Ich freue mich immer über Nachrichten - entweder über das Kontaktformular oder die sozialen Medien.',
            ContactFormName: 'Name:',
            ContactFormEmail: 'Email:',
            ContactFormMessage: 'Nachricht',
            ContactFormButton: 'senden',
            Biography: "Biographie",
            Calendar: "Termine",
            Archive: "Archive",
            Projects: "Projekte",
            Education: "Pädagogik",
            Collaborations: "Kooperationen",
            Media: "Medien",
            Recordings: "Diskographie",
            Gallery: "Galerie",
            Video: "Video",
            News:"Aktuell",
            Press: "Pressestimmen",
            NewsletterText: 'Aktuelles zu Konzerte und Aktivitäten gibt es in meinem Newsletter.',
            NewsletterSignUp: "Newsletter abonnieren",
            ViewConcert: 'Konzert ansehen',
            ViewArchive: 'Konzertarchiv',
            ReadMore: 'mehr lesen',
            ViewVideo: 'video ansehen',
            ShareThisPage: 'Teile diese Seite',
            BackToAllProjects: 'zurück zu allen Projekten',
            PhotoDownload: 'Herunterladen',
            DownloadFile: 'Biographie herunterladen',
            
            
            
            
            Management1: 'Für allgemeines Management und Buchungen kontaktieren Sie bitte ',
            Management1a: '(Senior Artist Manager bei YCAT).',
            Management2: 'Für nordamerikanisches Management und Buchungen wenden Sie sich bitte an ',
            Management2a: '(Senior VP und Künstlermanagement bei CAG).',


            CollaborationIntro: "Meine Begeisterung für Neue Musik führt regelmäßigem zu anregenden, experimentellen Kooperationen mit Komponist*innen der heutigen Zeit. Hier möchte ich ein paar der daraus entstandenen Werke näher vorstellen... ",
            EducationIntro: 'Durch meine Arbeit mit Schüler*innen und Student*innen in verschiedensten Szenarien suche ich immer wieder nach neuen Wegen, Kindern (und Erwachsenen) kreative Erfahrungen mit Musik zu ermöglichen. ',


            TheNightingale: "Des Kaisers Nachtigall",
            TheNightingaleTag: "„Die Nachtigall“ von Uģis Prauliņš für Blockflöte und gemischten Chor ist eine farbenfrohe und lebenslustige musikalische Erzählung des berühmten Märchens von Hans Christian Andersen. ",
            TheNightingaleDetails: "Vor langer Zeit lebte in China ein Kaiser. Der war so reich, dass alles in seinem Schloss funkelte und glitzerte. Dazu gehörte ihm einer der prachtvollsten Gärten der Welt. In ihm blühten Blumen, so bunt wie der Regenbogen. Auch die Nachtigall mochte den Garten und hüpfte am liebsten laut singend von Ast zu Ast… ",
            ConcertLink: "Das WDR Familienkonzert gibt es hier in voller Länge zum nachhören und sehen:",
            WDR: "WDR Rundfunkchor",
            NicolasFink: "Nicolas Fink - Leitung",
            Tabea: "Tabea Debus - Blockflöte",
            SeptemberOctober:"September - Oktober 2017",
              LanguageOfBirds: "The Language of Birds by Philip Cashian",
              LanguageTag: "Auftragskomposition des Londoner St John’s Smith Square für Tabea Debus, 2016",
              Language1: "Die Blockflöte nimmt im Kontext der Oper verschiedene Rollen ein. Eine der Hauptallegorien ist das Imitieren von Vogelstimmen, zu den wohl bekanntesten Beispielen zählen zum Beispiel Georg Friedrich Händels „Rinaldo“ oder Henry Purcells „The Fairy Queen“. Ob das nun bedeutet, dass die Blockflöte tatsächlich fähig ist, direkt mit den Vögeln zu kommunizieren, oder ob sie nur die geheime Sprache der Vögel, so wie sie der Vorstellung des Komponisten entspringt, illustriert, sei dahingestellt. Diese Faszination ist nicht nur ein allgegenwärtiger Aspekt alter Musik, sondern kann bis in das 21. Jahrhundert verfolgt werden – wie Philip Cashians „The Language of Birds“ [Die geheime Sprache der Vögel] einmal mehr beweist. ",
              Language2:"„In Mythologie und Volkserzählungen aus der ganzen Welt wird die Sprache der Vögel als magische Sprache dargestellt, und die Fähigkeit, diese zu verstehen gilt als Zeichen großer Weisheit. Während des Komponierens dieses kurzen, virtuosen Stücks begann ich zu realisieren, wie nah das Spielen der Blockflöte dem Singen und Sprechen steht. Das Instrument ist sozusagen eine natürliche Erweiterung oder Verstärkung des Körpers des Spielers.“ - Philip Cashian",
              Fantasie:"Im Lauf der vergangenen Jahre haben BlockflötistInnen weltweit daran gearbeitet, das Repertoire für dieses vielfältige und flexible Instrument ständig zu erweitern und ergänzen. Neben dem Wiederentdecken von Originalliteratur für die Blockflöte, zählt dabei für mich neben dem Arrangieren von (barocken) Werken besonders die Kollaboration mit zeitgenössischen KomponistInnen eine zentrale Rolle. Durch den ständigen Dialog entdeckt man hier nicht nur ständig neue Türen und Wege, sondern durch gemeinsame Neugier und Experimentierfreude erschließt sich ein unbegrenzter Schatz neuer Möglichkeiten. Aus diesem Grund habe ich 2017 anlässlich des 250. Todestages von Georg Philipp Telemann zusammen mit der Londoner City Music Foundation insgesamt dreizehn Auftragskompositionen vergeben. Diese eröffnen nicht nur neue Blickwinkel auf Barockmusik, sondern bauen musikalisch Brücken zwischen dem 18. und 21. Jahrhundert. Jedes der neuen Stücke für Blockflöte solo basiert auf einer von Telemanns XII Fantasie per il Flauto senza Basso, und baut dabei auf thematisches, harmonisches oder rhythmisches Material einer der Fantasien auf, spinnt musikalische Ideen weiter, vertieft sie oder interpretiert sie neu. ",
              Fantasie2:"Die CD XXIV Fantasie per il Flauto erscheint im April 2018 bei TYXart-the musicART Label. ",
              Fantasie3:"\"Es ist mir eine große Freude eines der Antwortstücke auf Telemanns 12 Fantasien für Flöte im Auftrag von Tabea Debus zu schreiben. Durch die Verbindung von historischem Ausgangsmaterial mit zeitgenössischen Reaktionen ganz vielfältiger Art werden die klanglichen Möglichkeiten der Blockflöte aus verschiedensten Perspektiven beleuchtet. Die große Virtuosität und musikalische Feinfühligkeit von Tabea Debus bieten mir die einmalige Chance das Potential dieses Instrumentes für meine kompositorischen Vorhaben zu erforschen.\"  - Arne Gieshoff",
              Fantasie4:"„Die 1991 in Münster geborene Blockflötistin Tabea Debus verkörpert einen modernen Künstlertypus abseits gängiger Genrevorstellungen. Weder Spezialistin allein für Alte noch für Neue Musik, nähert sie sich den unterschiedlichsten Stilrichtungen ohne falsche Scheu, mit großer Ernsthaftigkeit und wachem Entdeckerohr und -auge und gestaltet diese Vielheit als noch junge, aber bereits profunde Musikerin und Musikvermittlerin. Sie ist eine vitale und kommunikative Bühnenpersönlichkeit und schafft mit ihrem Repertoire und ihrem Auftreten etwas, das mich als zeitgenössischen Komponisten besonders fasziniert: sie begeistert ihre Zuhörerschaft für ihre (Blockflöten-) Kunst und so mancher entdeckt dabei neue Klangwelten und seine Liebe auch für die Neue Musik. Ihr von der City Music Foundation London gefördertes Projekt zum 250. Todestag Telemanns ist in diesem Zusammenhang geradezu vorbildlich: 12 Komponisten schreiben je ein neues Werk in Bezug zu je einer der 12 Fantasien Telemanns für Blockflöte solo.Ich musste keine Sekunde zögern, um Tabea Debus hierfür meine Beteiligung zuzusagen.“ - Prof. Frank Zabel",
              Fantasie5:"",
              Fantasie6:"Dani Howard: “Two and a Half Minutes to Midnight” (No. 1)    ",
              Fantasie7:"Alastair Penman: “Mirrored Lines” (No. 2)    ",
              Fantasie8:"Oliver Leith “Bendy Broken Telemann” (No. 3)",
              Fantasie9:"Leo Chadburn: “Si la passion pour les plantes exotiques” (No. 4) ",
              Fantasie10:"Fumiko Miyachi: “Air” (No. 5)",
              Fantasie11:"Max de Wardener (No. 6)",
              Fantasie12:"Arne Gieshoff: “Entr’acte” (No. 7)",
              Fantasie13:"Ronald Corp: “Meditation and Gigue” (No. 8)",
              Fantasie14:"Moritz Eggert: “Fantasia in Stereo” (No. 9)",
              Fantasie15:"Misha Mullov-Abbado “Fantasia 10.2.0” (No. 10)",
              Fantasie16:"Frank Zabel “…fizzling out…” (No. 11)",
              Fantasie17:"Colin Matthews “Meditation“ (No. 12)",
              Fantasie18:"Laura Bowler: “TV Man” (all Fantasias)"
          }
          
    
}