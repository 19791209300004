import React, { Component } from 'react'
import { getRecordings } from '../contentful'
import Translator from '../components/i18n/Translator'
import Recording from './Recording'
import TitleBar from './TitleBar'

class Discography extends Component{
    state = {
        recordings: []
    }


   


    componentDidMount(){
        var switchedLang = window.localStorage.getItem('lang')
        if(!switchedLang){switchedLang = 'en-US'}


        getRecordings(switchedLang).then(data => this.setState({recordings: data}))
    }

    render(){

        return (
            <div className="content">
                    <TitleBar title={<Translator path="Recordings"/>} />

                {this.state.recordings.map((cd, index) => 
             <Recording cd={cd} key={index} index={index} />
        
                )}
            </div>
        )
    }
}

export default Discography