import React from "react";
import { Link } from "react-router-dom";
import Translator from "../i18n/Translator";
import { Row, Col } from "react-bootstrap";
import marked from "marked";

const EducationProject = ({ project }) => {

    let desc;
    {project.fields.content ? desc = marked(project.fields.content) : desc = null}

    let excerpt;
{project.fields.content ? excerpt = desc.split(" ").splice(0, 65).join(" ") + "..." : excerpt = null};


  return (
    <div className="event">
      <Row>
        <Col md={4}>
          {project.fields.mainImage ? (
            <img style={{width:'100%'}}
              src={project.fields.mainImage.fields.file.url}
              alt=""
            />
          ) : null}
        </Col>
        <Col md={8} >
        <div  >

          <h5>{project.fields.title}</h5>
            <section dangerouslySetInnerHTML={{__html: excerpt}} />
            </div>
          <Link
            className="xsmall-button"
            to={"/education/" + project.sys.id}
          >
            <Translator path="ReadMore" />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default EducationProject;
