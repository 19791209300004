import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getGallery } from '../../contentful'
import { Row, Col } from 'react-bootstrap'


const ImageGrid = (props) => {

const [images, setImages] = useState(null)

useEffect(() => {
    getGallery().then(data => setImages(data))
}, [])

  return (
    <div >
      <Row>
      {images && images.map(image => (
        <Col md={4}>
        <div>
        <motion.div  key={image.url} 
          
          whileHover={{ opacity: 1 }}s
          onClick={() => { 
            props.setSelectedImg(image.fields.image.fields.file.url)
          props.setTitle(image.fields.title)}
        }
        >
          <motion.img  className="photo" src={image.fields.image.fields.file.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
        </motion.div>
        {image.fields.title && <motion.p style={{color:'whitesmoke', textAlign:'center'}} initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }} >{image.fields.title}</motion.p>}
        </div>
        </Col>
      ))}
      </Row>
    </div>
  )
}

export default ImageGrid;