import React, { Component } from "react";
import { getEducationProject } from "../../contentful";
import marked from "marked";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SingleProjectPage from "./SingleProjectPage";

class EducationProjectFull extends Component {
  state = {
    project: [],
  };

  componentDidMount() {
    var switchedLang = window.localStorage.getItem("lang");

    getEducationProject(this.props.match.params.id, switchedLang).then((data) =>
      this.setState({ project: data })
    );
  }

  render() {
    return (
      <div className="recording">
        {this.state.project.map((item) => (
          <SingleProjectPage project={item} />
        ))}
      </div>
    );
  }
}

export default EducationProjectFull;
