import React from 'react'


const Footer = (props) => {
return <div className="footer">
    <small>Designed and built by Adam Woolf @ <a href='https://www.webspinner.eu'>WebSpinner.eu</a></small>
   
    <small>Portrait photographs of Tabea Debus by <a href="http://benjaminealovega.com/"> Benjamin Ealovega, </a><a href="http://www.citymusicfoundation.org/artist/tabea-debus/">City Music Foundation</a></small>
    
</div>

}

export default Footer