import React, { Component } from 'react'
import Translator from '../i18n/Translator'
import { getEducation } from '../../contentful'
import EducationProject from './EducationProject'
import TitleBar from '../TitleBar'


class Education extends Component {
    state = {
        projects: []
    }

    componentDidMount(){
        var switchedLang = window.localStorage.getItem("lang");
        if(!switchedLang){switchedLang = 'en-US'}

        getEducation(switchedLang).then(data => this.setState({projects: data}))
    }
    render(){
        return (
            <div className="content">
        <TitleBar title={<Translator path="Education"/>} />
        <p style={{textAlign: 'center',  color: 'rgb(235, 210, 192'}} ><Translator path="EducationIntro"/></p>

        {this.state.projects.map(project => <EducationProject project={project} />)}
        </div>

        )
    }
}

export default Education
