import React from "react";
import marked from "marked";
import "./video.css";
import { Col } from 'react-bootstrap'

const video = ({video}) => {
  const film = marked(video.fields.youTube);

  return (
    <Col md={6}>
    <div  className="video ">
      <div>
      <h5>{video.fields.title}</h5>
  <p>{video.fields.description}</p>
  </div>
<div>
      <section dangerouslySetInnerHTML={{ __html: film }} />
      </div>
    </div>
    </Col>
   
  );
};

export default video;
