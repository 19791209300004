import React from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaFacebook,
  FaSpotify,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div style={{ padding: "15px" }} className='toolbar__toggle-button'>
          <DrawerToggleButton click={props.click} />
        </div>

        <div className='header'>
          <Link onClick={props.click} to='/'>
            <h4>Tabea Debus</h4>
          </Link>

          <hr></hr>
        </div>
        <ul>
          <li>
            <Link onClick={props.click} to='/biography'>
              <Translator path='Biography' />
            </Link>
          </li>
          <li>
            <Link onClick={props.click} to='/calendar'>
              <Translator path='Calendar' />
            </Link>
          </li>
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <Translator path='Projects' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/education'>
                  <Translator path='Education' />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/collaborations'>
                  <Translator path='Collaborations' />
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <Translator path='Media' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/recordings'>
                  <Translator path='Recordings' />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/gallery'>
                  <Translator path='Gallery' />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/video'>
                  <Translator path='Video' />
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <Translator path='News' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/posts'>
                  <Translator path='News' />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <Link onClick={props.click} to='/press'>
                  <Translator path='Press' />
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <li>
            <Link onClick={props.click} to='/contact'>
              <Translator path='Contact' />
            </Link>
          </li>
        </ul>
        <div className='social'>
          <a href='https://www.instagram.com/tabeadebus/' target='_blank'>
            <FaInstagram size={35} />
          </a>
          <a
            href='https://www.facebook.com/TabeaDebusRecorder/'
            target='_blank'
          >
            <FaFacebook size={35} />
          </a>

          <a href='https://twitter.com/TabeaDebus' target='_blank'>
            <FaTwitter size={35} />
          </a>

          <a
            href='https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg'
            target='_blank'
          >
            <FaYoutube size={35} />
          </a>

          <a
            href='https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg'
            target='_blank'
          >
            <FaSpotify size={35} />
          </a>
        </div>
        <div className='i18n'>
          <I18n />
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
