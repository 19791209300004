import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaSpotify, FaItunes } from 'react-icons/fa'
import marked from 'marked'
import { Link } from 'react-router-dom'
import Translator from './i18n/Translator'

const Recording = (props) => {
    const info = marked(props.cd.fields.tracklist) 
    let review;
    {props.cd.fields.info ?  review = marked(props.cd.fields.info) : review = null}

    let videoEmbed;
    {props.cd.fields.videoEmbed ? videoEmbed = marked(props.cd.fields.videoEmbed) : videoEmbed = null}
    let listing =
    
    // {0 === props.index % 2 ? listing =
    <Row>
    <Col md={4} style={{display:'flex', alignItems:'center', flexDirection:'column'}} >
    <img style={{width:'100%' }} src={props.cd.fields.image.fields.file.url} />
    <div className="recording-images">
    <a id="spotify" href={props.cd.fields.spotifyUrl} target="_blank"><FaSpotify size={25} /></a>
    <a id="itunes" href={props.cd.fields.iTunesLink} target="_blank"><FaItunes  size={25}/></a>
    <a className="label-name" href={props.cd.fields.labelUrl} target="_blank">{props.cd.fields.labelName}</a>
    </div>
    </Col>
    <Col md={8}>
   <h5>{props.cd.fields.title}</h5>
    <p><em>{props.cd.fields.artist}</em></p>
    {props.cd.fields.videoEmbed && <div dangerouslySetInnerHTML={{__html: videoEmbed }} />  }

    <section dangerouslySetInnerHTML={{ __html: info }} />
    <section dangerouslySetInnerHTML={{ __html: review }} />
    {/* {props.cd.fields.videoButton && <Link to="/video" className="xsmall-button">video</Link> } */}
    
    </Col>
    </Row>
    
    
    
//     : listing =  <Row>
//     <Col md={6}>
//    <h5>{props.cd.fields.title}</h5>
//     <p><em>{props.cd.fields.artist}</em></p>
//     <section dangerouslySetInnerHTML={{ __html: info }} />
//     <section dangerouslySetInnerHTML={{ __html: review }} />
//     </Col>
//     <Col md={6}>
//     <img style={{}} src={props.cd.fields.image.fields.file.url} />
//     <div className="recording-images">
//     <a id="spotify" href="props.cd.fields.spotifyUrl" target="_blank"><FaSpotify size={25} /></a>
//     <a id="itunes" href="props.cd.fields.iTunesLink" target="_blank"><FaItunes  size={25}/></a>
//     <a className="label-name" href={props.cd.fields.labelUrl} target="_blank">{props.cd.fields.labelName}</a>
//     </div>
//     </Col>
//     </Row>
//     }



    return (
        <div className="recording">
       {listing}
    </div>
    )
}

export default Recording