import React from "react";
import Event from "../Event";
import { getCalendar, getCalendarArchive } from "../../contentful";
import Translator from "../i18n/Translator";
import { Row, Col } from "react-bootstrap";
import photo1 from '../../assets/imgs/gallery/Bogota.jpg'
import photo2 from '../../assets/imgs/gallery/Bolzano.jpg'
import TitleBar from '../TitleBar'


class Calendar extends React.Component {
  state = {
    posts: [],
    archive: [],
    fullArchive: false,
  };

  toggleArchive = () => {
    !this.state.fullArchive
      ? this.setState({ fullArchive: true })
      : this.setState({ fullArchive: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    getCalendar().then((data) => this.setState({ posts: data }));
    getCalendarArchive().then((data) => this.setState({ archive: data }));
  }
  render() {
    let archive;
    {
      !this.state.fullArchive
        ? (archive = this.state.archive
            .slice(0, 4)
            .map((item) => <Col md={6}> <Event class="event" date={item} /></Col>))
        : (archive = this.state.archive.map((item) => <Col md={6}><Event class="event" date={item} /></Col>));
    }

    return (
      <div className="content">
      
      <TitleBar title={<Translator path="Calendar"/>} />

        <Row>
         
           
           

            {this.state.posts.map((item) => (
              <Col md={6}> 
              <Event class="event" date={item} />
              </Col>

            ))}
         
        </Row>
        <Row>
          <Col md={6}> <img style={{width:'100%'}} src={photo1} />
</Col>
          <Col md={6}><img style={{width:'100%'}} src={photo2} /></Col>
        </Row>
          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          </div>

            <h4 style={{textAlign:'center', paddingTop:'20px'}}>
              <Translator path="Archive" />
            </h4>
            <Row>

            {archive}
            </Row>

            <button onClick={this.toggleArchive} className="xsmall-button">
              <Translator path="ViewArchive"/>
            </button>
      </div>
    );
  }
}
export default Calendar;
