import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import Translator from "./i18n/Translator";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";

const BlogItem = ({ article }) => {
  console.log(article);
  const { title, image, content, label, description } = article.fields;
  const { id } = article.sys;
  const postBody = content ? marked(content) : "";
  const excerpt = postBody.split(" ").splice(0, 20).join(" ") + "...";

  return (
    <div className='news-list'>
      <h4 style={{ color: "rgb(119, 54, 4", margin: "10px" }}>{title}</h4>

      {image && (
        <img
          style={{ height: "300px", marginBottom: "20px" }}
          src={image.fields.file.url}
        />
      )}
      {description && <p style={{ textAlign: "center" }}>{description}</p>}
      {/* <section dangerouslySetInnerHTML={{ __html: excerpt }} /> */}
      <div></div>
      <div className='post-list-buttons'>
        <Link
          style={{ margin: "10px 0" }}
          className='xsmall-button'
          to={"/posts/" + id}
        >
          <Translator path='ReadMore' />
        </Link>
        <small>
          <Translator path='ShareThisPage' />{" "}
        </small>

        <div id='social'>
          <TwitterShareButton
            url={window.location}
            children={<TwitterIcon size={24} />}
          />
          <LinkedinShareButton
            url={window.location}
            children={<LinkedinIcon size={24} />}
          />
          <FacebookShareButton
            url={window.location}
            children={<FacebookIcon size={24} />}
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

export default BlogItem;
