import React from "react";


const TitleBar = (props) => {
  return (
    <div className="title-bar">
      <div>
        <h3 style={{ textAlign: "center" }}>{props.title}</h3>
      </div>
    
    </div>
  );
};

export default TitleBar;
