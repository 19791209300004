import React from 'react'
import marked from 'marked'
import { Link } from 'react-router-dom'
import Translator from '../i18n/Translator'
import { Col, Row } from'react-bootstrap'

const Collaboration = ({project}) => {
  let desc;
  {project.fields.content ? desc = marked(project.fields.content) : desc = null}

  let excerpt;
{project.fields.content ? excerpt = desc.split(" ").splice(0, 35).join(" ") + "..." : excerpt = null};

return (
  <div className="event">
    <Row>
      <Col md={4}>
        {project.fields.mainImage ? (
          <img style={{width:'100%'}}
            src={project.fields.mainImage.fields.file.url}
            alt=""
          />
        ) : null}
      </Col>
      <Col md={8} >
        <h5>{project.fields.title}</h5>
          <section dangerouslySetInnerHTML={{__html: excerpt}} />
        <Link
          className="xsmall-button"
          to={"/collaborations/" + project.sys.id}
        >
          <Translator path="ReadMore" />
        </Link>
      </Col>
    </Row>
  </div>
    )
}

export default Collaboration;